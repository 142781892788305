import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';
import * as style1 from '../../pages/dashboard/detail.module.css';
import SuccessPopup from "./successPopup";
import { connectAgent } from "../Api/formApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { upDateReferralFee } from "../Api/ListingApi";
import ToastPopup from "./ToastPopup";

function RefrelFee(props) {
    const { show, toggleShow, agentId, handleClose} = props;
    const [success, setSucess] = useState(false);
    const [agentName, setAgentName] = useState('')
    const toggleSuccess = () => setSucess(p => !p);
    const [refrelFee, setRefrelFee] = useState(props.value)
    const [error, setError] = useState()

    const [succes, setSuces] = useState(false);
    const [messages, setMessages] = useState("")
    const toggleSucces = () => setSuces(p => !p);
    useEffect(() => {
        if (props.referralAmount !== undefined) {
            setRefrelFee(props.referralAmount)
        }
    }, [props.referralAmount])

    const handleRefrel = (e) => {
        setError("")
        if (e.target.value.length <= 2) {
            setRefrelFee(e.target.value)
        }
    }
    const transferApis = async () => {
        if (refrelFee !== null && refrelFee !== "" && refrelFee > 0) {
            if (props.updateFee === "updateFee") {
                let data = {
                    referral_fee: refrelFee
                }
                await upDateReferralFee(props.propertyId, data).then((res) => {
                    toggleShow()
                    props.inBound()
                    props.outBound()
                    setMessages(res.data.message)
                    toggleSucces()
                }).catch((error) => {
                    console.log(error)
                    toast.error('A request has already been sent', {
                        position: "top-right",
                        autoClose: 3000,
                        closeOnClick: true,
                    });
                })
            }
            else {
                let data = []
                agentId.forEach((item) => {
                    if (item.is_active === false) {
                        data.push(item._id)
                    }
                })
                if (data.length <= 1) {
                    let value = agentId.findIndex((a) => a._id === data[0])
                    setAgentName(agentId[value] !== undefined && agentId[value].name)
                }
                else {
                    setAgentName('Agents')
                }
                let createPostData = {
                    "prospect_id": props.data._id,
                    "agent_id": data,
                    "referral_fee": refrelFee,
                };
                await connectAgent(createPostData)
                    .then((res) => {
                        if (res.data.status === 0) {
                            setTimeout(() => toggleShow(), 1000)
                            setMessages(res.data.message)
                            toggleSucces()

                        }
                        else {
                            toggleSuccess()
                            setTimeout(() => toggleShow(), 3000)
                        }
                    }).catch(() => {
                        toggleShow()
                        setMessages("Cannot read property 'agent_id' of null")
                        toggleSucces()
                    })
            }
        }
        else {
            setError("Enter a value")
        }

    }
    const hanldeClose = () => {
        handleClose()
        setRefrelFee("")
    }

    return (
        <div>
            <ToastContainer />
            <ToastPopup success={succes} toggleSuccess={toggleSucces} messages={messages} />

            <SuccessPopup success={success} agentName={agentName} toggleSuccess={toggleSuccess} />
            <Modal show={show} onHide={hanldeClose} className="special_modal ModalDiv" dialogClassName="my-modal" size="md">
                <div className="PopUpBorderDiv" style={{ borderRadius: "1%" }}>

                    <Modal.Header closeButton className="team-popup-margin teampopup-title"  >
                        <Modal.Title className='form-field-label form-label-team '><h5 className='teamText'>Referral  Fee</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="team-popup-margin pb-0" >
                        <div className='row mb-5'>
                            <div className="col-md-6">
                                <input type="number" name="createGroup" value={refrelFee} onChange={handleRefrel} placeholder='Referral fee' className={style1.RefrelFee} /> %
                                <h6 style={{ color: "red" }}>{error}</h6>
                            </div>

                            <div className="col-md-6">
                                <Button variant="primary" onClick={transferApis} className={style1.refsubmitbut}>
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>

                </div>
            </Modal>
        </div>
    )
}
export default RefrelFee